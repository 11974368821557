import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import ReCAPTCHA from 'react-google-recaptcha';
import './ScheduleShowing.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const ScheduleShowing = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('uid');
    const [captcha, setCaptcha] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [propertyData, setPropertyData] = useState([]);
    const [unitData, setUnitData] = useState({});
    const [scheduleData, setScheduleData] = useState({
        property: '',
        unitID: '',
        name: '',
        email: '',
        phone: '',
        option1: '',
        option2: '',
        option3: '',
    });

    const sanitizeInput = (input) => {
        return input.replace(/[^a-zA-Z0-9@.\-\s]/g, '').trim();
    };

    const handleCaptcha = (val) => {
        setCaptcha(!!val);
    };

    const validateForm = () => {
        if (!scheduleData.property) {
            toast.warning('Property selection is required!');
            return false;
        }
        if (!scheduleData.name) {
            toast.warning('Name is required!');
            return false;
        }
        if (!/^[0-9]{10}$/.test(scheduleData.phone)) {
            toast.warning('Phone number must be 10 digits!');
            return false;
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(scheduleData.email)) {
            toast.warning('Invalid email format!');
            return false;
        }
        if (!scheduleData.option1) {
            toast.warning('At least one date and time option is required!');
            return false;
        }
        if (!captcha) {
            toast.warning('Please complete the CAPTCHA verification!');
            return false;
        }
        return true;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
            timeZone: 'America/Toronto',
        });
    };

    const onSubmit = () => {
        if (!validateForm()) return;

        const formattedScheduleData = {
            ...scheduleData,
            name: sanitizeInput(scheduleData.name),
            email: sanitizeInput(scheduleData.email),
            phone: sanitizeInput(scheduleData.phone),
            option1: formatDate(scheduleData.option1),
            option2: scheduleData.option2 ? formatDate(scheduleData.option2) : '',
            option3: scheduleData.option3 ? formatDate(scheduleData.option3) : '',
        };

        const apiUrl = `${process.env.REACT_APP_API_URL}/v1/mailsend/propertyBookingRequest`;

        fetch(apiUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formattedScheduleData),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 200) {
                    toast.success(result.res || 'Submission successful!');
                    setScheduleData({
                        property: '',
                        unitID: '',
                        name: '',
                        email: '',
                        phone: '',
                        option1: '',
                        option2: '',
                        option3: '',
                    });
                } else {
                    toast.error(result.res || 'Submission failed!');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                toast.error('An error occurred. Please try again.');
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setScheduleData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (userId) {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/listings/kwpweb/unit/${userId}`, { method: 'GET' });
                    if (response.status === 200) {
                        const responseData = await response.json();
                        setUnitData(responseData);
                        setScheduleData((prev) => ({
                            ...prev,
                            property: responseData.Property?.Name || '',
                            unitID: responseData.Unit?.Id || '',
                        }));
                    } else {
                        navigate('/');
                    }
                } else {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/listings/kwpweb/listing`, { method: 'GET' });
                    const data = await response.json();
                    setPropertyData(data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [navigate, userId]);

    useEffect(() => {
        if (scheduleData.property.includes('/')) {
            const unitID = scheduleData.property.split('/')[1]; // Extracts the part after '/'
            setScheduleData((prev) => ({
                ...prev,
                unitID, // Ensures unitID is updated
            }));
        }
    }, [scheduleData.property]);

    if (loading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }

    return (
        <>
            <KWNavbar />
            <ToastContainer />
            <div className="container mt-5 pt-5">
                <div className="mt-5">
                    <div className="ScheduleShowing-header">WANT TO SEE THIS PROPERTY?</div>
                    <div className="ScheduleShowing-top-para">
                        Please fill out and submit the form below with your information and your preferred dates/times for a showing. We will do our best to accommodate your schedule!
                    </div>
                </div>
                <div className="ScheduleShowing-form">
                    <div className="ScheduleShowing-input">
                        <label htmlFor="Property">Property</label>
                        {userId ? (
                            <div className="confirm-property-RgridT">
                                <strong>{unitData.Property?.Name}</strong>
                            </div>
                        ) : (
                            <select
                                className="required-number required-number2 w-100"
                                value={scheduleData.property}
                                onChange={handleChange}
                                name="property"
                                id="Property"
                            >
                                <option value="">Select Property</option>
                                {propertyData.length > 0 &&
                                    propertyData.map((data) => (
                                        <option key={data.Unit?.Id} value={`${data.Property.Name}/${data.Unit.Id}`}>
                                            {data.Property.Name}
                                        </option>
                                    ))}
                            </select>
                        )}
                    </div>
                    <div className="ScheduleShowing-input">
                        <label htmlFor="YourName">Your Name*</label>
                        <input
                            type="text"
                            value={scheduleData.name}
                            onChange={handleChange}
                            className="required-number required-number2 w-100"
                            name="name"
                            id="YourName"
                        />
                    </div>
                    <div className="ScheduleShowing-input">
                        <label htmlFor="Phone">Phone*</label>
                        <input
                            type="text"
                            value={scheduleData.phone}
                            onChange={handleChange}
                            className="required-number required-number2 w-100"
                            name="phone"
                            id="Phone"
                        />
                    </div>
                    <div className="ScheduleShowing-input">
                        <label htmlFor="EmailAddress">Email Address*</label>
                        <input
                            type="text"
                            value={scheduleData.email}
                            onChange={handleChange}
                            className="required-number required-number2 w-100"
                            name="email"
                            id="EmailAddress"
                        />
                    </div>
                    <div className="ScheduleShowing-Pleaseprovide">
                        <b>Date and Time of Availability:</b>
                        <p>(Please provide a couple of options if possible, and we will do our best to accommodate you.)</p>
                    </div>
                    {['option1', 'option2', 'option3'].map((option, idx) => (
                        <div key={option} className="ScheduleShowing-input">
                            <label htmlFor={option}>{`Option #${idx + 1}`}</label>
                            <input
                                value={scheduleData[option] || ''} // Ensure no uncontrolled component warnings
                                onChange={(e) => setScheduleData((prev) => ({
                                    ...prev,
                                    [option]: e.target.value, // Removed sanitization for dates
                                }))}
                                className="form-control"
                                type="datetime-local"
                                name={option}
                                id={option}
                            />
                        </div>
                    ))}
                    <div className="ScheduleShowing-buttons">
                        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={handleCaptcha} />
                        <div className="button-container">
                            <button
                                onClick={() => window.history.back()}
                                type="button"
                                className="btn btn-secondary CPgridButton1"
                            >
                                Back
                            </button>
                            <button
                                onClick={onSubmit}
                                type="button"
                                className={`btn btn-primary ms-1 CPgridButton2 ${captcha ? '' : 'disabled'}`}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <KWFooter />
        </>
    );
};