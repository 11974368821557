import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import KWAdminNavbar from '../../components/navbar/AdminNav';
import { LineWave } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DOMPurify from 'dompurify';
import agents from '../../data/agents.json';

const initialShowingData = {
  description: '',
  summary: '',
  start: {
    dateTime: '',
    timeZone: "America/Los_Angeles"
  },
  end: {
    dateTime: '',
    timeZone: "America/Los_Angeles"
  },
  extendedProperties: {
    shared: {
      propertyId: '',
      agentName: ''
    },
    private: {}
  }
};

const AddEvent = () => {
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const [property, setProperty] = useState([]);
  const [showingData, setShowingData] = useState(initialShowingData);
  const formRef = useRef(null);
  const [selectedAgent, setSelectedAgent] = useState(null);

  useEffect(() => {
    const isLogin = sessionStorage.getItem('isLogin');
    if (isLogin !== 'true') {
      navigate('/admin');
    } else {
      fetchProperties();
    }
  }, [navigate]);

  const fetchProperties = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1/listings/kwpweb/listing`);
      setProperty(data);
    } catch (err) {
      console.error(err);
      toast.error('Failed to fetch properties. Please try again later.');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
  
    setShowingData((prev) => {
      if (name === "property") {
        return {
          ...prev,
          extendedProperties: {
            ...prev.extendedProperties,
            shared: {
              ...prev.extendedProperties.shared,
              propertyId: value,
            },
          },
          summary: `[o] ${event.target.selectedOptions[0].innerText}`,
        };
      }
  
      if (name === "start") {
        // Add 30 minutes to the start time (as string manipulation)
        const [date, time] = value.split("T");
        const [hours, minutes] = time.split(":").map(Number);
  
        let newMinutes = minutes + 30;
        let newHours = hours;
  
        if (newMinutes >= 60) {
          newMinutes -= 60;
          newHours += 1;
        }
  
        const endTime = `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(2, "0")}`;
        const endDateTime = `${date}T${endTime}`;
  
        return {
          ...prev,
          start: {
            ...prev.start,
            dateTime: value,
          },
          end: {
            ...prev.end,
            dateTime: endDateTime, // Directly set the end date/time
          },
        };
      }
  
      if (name === "end") {
        return {
          ...prev,
          end: {
            ...prev.end,
            dateTime: value,
          },
        };
      }
  
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  
  
  


  const handleAgent = (value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    setShowingData(prev => {
      return {
        ...prev,
        extendedProperties: {
          ...prev.extendedProperties,
          shared: {
            ...prev.extendedProperties.shared,
            agentName: sanitizedValue,
          }
        }
      };
    });
    setSelectedAgent(() => agents.filter(a => a.name === value)[0]);
  };

  const handleAddShowing = async () => {
    const { propertyId, agentName } = showingData.extendedProperties.shared;
    if (!propertyId || !agentName || !showingData.start.dateTime || !showingData.end.dateTime) {
      toast.error('Please fill in all required fields.');
      return;
    }
  
    if (new Date(showingData.start.dateTime) >= new Date(showingData.end.dateTime)) {
      toast.error('Start date must be before end date.');
      return;
    }
  
    setSubmitting(true);
    try {
      const payload = {
        ...showingData,
        start: {
          ...showingData.start,
          dateTime: new Date(showingData.start.dateTime).toISOString(), // Convert to ISO format
        },
        end: {
          ...showingData.end,
          dateTime: new Date(showingData.end.dateTime).toISOString(), // Convert to ISO format
        },
      };
  
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/v1/calender/event`, {
        ...payload,
        description: `${payload.description}`,
        colorId: `${selectedAgent?.colorId}`,
      });
  
      if (res.status === 200) {
        formRef.current.reset();
        setShowingData(initialShowingData);
        setSelectedAgent(null);
        toast.success('Event added successfully!');
      }
    } catch (err) {
      console.error(err);
      toast.error('Error adding event. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };
  
  return (
    <>
      <KWAdminNavbar />
      <ToastContainer />
      <Container className="pt-5">
        <div className="form-content">
          <Col className="mx-auto" sm={12} lg={6}>
            <Card className="pt-3 border-0 form-card mt-4">
              <h4 className="text-center">Add Showing</h4>
              <Form ref={formRef}>
                <Form.Group className="mb-3">
                  <Form.Label>Property <span className='text-danger'>*</span></Form.Label>
                  <Form.Select onChange={handleChange} name='property' required>
                    <option value="" >Select a property</option>
                    {property.length > 0 ? (
                      property.map((p) => (
                        <option key={p.Unit.Id} value={p.Unit.Id}>
                          {p.Property.Name}
                        </option>
                      ))
                    ) : (
                      <option disabled>No properties available</option>
                    )}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Agent Name <span className='text-danger'>*</span></Form.Label>
                  <DropdownButton
                    className='form-control agent p-0'
                    id="dropdown-basic-button"
                    title={selectedAgent?.name || 'Select Agent'}
                    style={{ backgroundColor: selectedAgent?.color_code || 'white' }}
                  >
                    {agents.length > 0 ? (
                      agents.map((A, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => handleAgent(A.name)}
                          className='d-flex justify-content-between align-items-center'
                        >
                          {A.name}
                          <span style={{ color: `${A.color_code}`, fontSize: '30px' }}>●</span>
                        </Dropdown.Item>
                      ))
                    ) : (
                      <Dropdown.Item disabled>No Agent available</Dropdown.Item>
                    )}
                  </DropdownButton>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Start Date <span className='text-danger'>*</span></Form.Label>
                  <Form.Control onChange={handleChange} name='start' type="datetime-local" required />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>End Date <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="end"
                      type="datetime-local"
                      value={showingData.end.dateTime || ''}
                      required
                    />
                    </Form.Group>


                <Form.Group className="mb-3">
                  <Form.Label>Description (Optional)</Form.Label>
                  <Form.Control
                    as="textarea"
                    onChange={handleChange}
                    name='description'
                    rows={3}
                    placeholder="Description"
                    required
                  />
                </Form.Group>

                <Button
                  onClick={handleAddShowing}
                  className='btn btn-primary mt-2'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <LineWave
                      height="20px"
                      width="70px"
                      color="#ffffff"
                      ariaLabel="line-wave"
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form>
            </Card>
          </Col>
        </div>
      </Container>
    </>
  );
};

export default AddEvent;
